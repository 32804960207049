const contact = {
    title: "Contact Us",
    link1: "Open Source",
    href1: "",
    link2: "Privacy Policy",
    href2: "",
    link3: "Code of Conduct",
    href3: "",
    link4: "WiCS",
    href4: "https://cs.uwaterloo.ca/wics",
};

export default contact;